import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { useApp } from "../components/RealmApp";
import { motion } from "framer-motion";
import { FaUserCircle, FaFilePdf, FaUpload, FaFileDownload } from "react-icons/fa";

const PatientDetails = () => {
  const location = useLocation();
  const { patient } = location.state;
  const [file, setFile] = useState(null);
  const [pdfs, setPdfs] = useState([]);
  const app = useApp();
  const doctorId = app.currentUser.id;
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", patient.userId);
    formData.append("doctorId", doctorId);
    axios.defaults.withCredentials = true;
    try {
      await axios.post(`${apiUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("File uploaded successfully");
      fetchPdfs();
    } catch (error) {
      console.error("Failed to upload file:", error);
      alert("Failed to upload file");
    }
  };

  const fetchPdfs = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/pdfs/${patient.userId}`
      );
      setPdfs(response.data);
    } catch (error) {
      console.error("Failed to fetch PDFs:", error);
    }
  };

  useEffect(() => {
    fetchPdfs();
  }, []);

  if (!patient) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-2xl font-semibold text-gray-600">Loading...</div>
      </div>
    );
  }

  const excludedFields = ['_id', 'userId', 'files', '__v'];

  const patientInfo = Object.entries(patient).filter(([key]) => !excludedFields.includes(key));

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold text-gray-900 mb-6 flex items-center">
            <FaUserCircle className="mr-2 text-teal-500" />
            Patient Details
          </h1>
          <div className="bg-white shadow-xl rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">Patient Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {patientInfo.map(([key, value]) => (
                  <div key={key} className="bg-gray-50 p-4 rounded-lg">
                    <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider">{key.replace(/_/g, ' ')}</h3>
                    <p className="mt-1 text-lg text-gray-900">
                      {Array.isArray(value) ? value.join(', ') : value.toString()}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <motion.div
            className="mt-8 bg-white shadow-xl rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4 flex items-center">
                <FaFilePdf className="mr-2 text-teal-500" />
                Upload Prescription
              </h2>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select PDF to upload
                </label>
                <div className="mt-1 flex items-center">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="sr-only"
                    id="file-upload"
                  />
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-teal-600 hover:text-teal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500"
                  >
                    <span className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                      <FaUpload className="mr-2" />
                      Choose file
                    </span>
                  </label>
                  <p className="ml-3 text-sm text-gray-600">
                    {file ? file.name : "No file chosen"}
                  </p>
                </div>
                <button
                  onClick={handleFileUpload}
                  className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                  <FaUpload className="mr-2" />
                  Upload Prescription
                </button>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="mt-8 bg-white shadow-xl rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4 flex items-center">
                <FaFileDownload className="mr-2 text-teal-500" />
                Uploaded Prescriptions
              </h2>
              {pdfs.length > 0 ? (
                <ul className="divide-y divide-gray-200">
                  {pdfs.map((pdf) => (
                    <li key={pdf._id} className="py-4">
                      <a
                        href={`${apiUrl}/pdf/${pdf._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-teal-600 hover:text-teal-800"
                      >
                        <FaFilePdf className="mr-2" />
                        {pdf.pdfName}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500">No prescriptions uploaded yet.</p>
              )}
            </div>
          </motion.div>

          <motion.div
            className="mt-8 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <Link
              to="/doctor-warning"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
            >
              Back to Dashboard
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default PatientDetails;
