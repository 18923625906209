import React from 'react';
import { motion } from 'framer-motion';
import { FaCheckSquare, FaSquare, FaEdit } from 'react-icons/fa';

const MedicalHistory = ({ data, setData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setData({ ...data, medical_conditions: [...data.medical_conditions, name] });
        } else {
            setData({ 
                ...data, 
                medical_conditions: data.medical_conditions.filter(condition => condition !== name) 
            });
        }
    };

    const medicalConditions = [
        "Hypertension (High Blood Pressure)",
        "Diabetes Mellitus",
        "Asthma",
        "Chronic Obstructive Pulmonary Disease (COPD)",
        "Coronary Artery Disease",
        "Congestive Heart Failure",
        "Hyperlipidemia (High Cholesterol)",
        "Obesity",
        "Depression",
        "Anxiety Disorders",
        "Thyroid Disorders",
        "Osteoarthritis",
        "Rheumatoid Arthritis",
        "Chronic Kidney Disease",
        "Gastroesophageal Reflux Disease (GERD)",
        "Peptic Ulcer Disease",
        "Stroke",
        "Chronic Liver Disease",
        "Cancer",
        "Chronic Pain Syndrome",
        "Migraine Headaches",
        "Chronic Fatigue Syndrome",
        "Fibromyalgia",
        "Irritable Bowel Syndrome (IBS)",
        "Inflammatory Bowel Disease (Crohn's Disease, Ulcerative Colitis)",
        "Chronic Sinusitis",
        "Sleep Apnea",
        "Attention Deficit Hyperactivity Disorder (ADHD)",
        "Bipolar Disorder",
        "Schizophrenia",
        "Post-Traumatic Stress Disorder (PTSD)",
        "Substance Abuse Disorders",
        "Chronic Bronchitis",
        "Osteoporosis",
        "Multiple Sclerosis",
        "Parkinson's Disease",
        "Chronic Migraines",
        "Chronic Obstructive Sleep Apnea (OSA)",
        "Systemic Lupus Erythematosus (SLE)",
        "Chronic Pancreatitis",
        "Other",
        "N/A"
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
        >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Medical History
            </h2>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mb-6"
            >
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Do you have any of the following medical conditions? Check all that apply.
                </label>
                <div className="mt-2 space-y-2 max-h-60 overflow-y-auto pr-2">
                    {medicalConditions.map(condition => (
                        <motion.div
                            key={condition}
                            whileHover={{ scale: 1.02 }}
                            className="flex items-center"
                        >
                            <label className="inline-flex items-center cursor-pointer w-full">
                                <input
                                    type="checkbox"
                                    name={condition}
                                    checked={data.medical_conditions.includes(condition)}
                                    onChange={handleCheckboxChange}
                                    className="hidden"
                                />
                                {data.medical_conditions.includes(condition) ? (
                                    <FaCheckSquare className="text-teal-500 mr-2" />
                                ) : (
                                    <FaSquare className="text-gray-300 mr-2" />
                                )}
                                <span className="text-sm text-gray-700">{condition}</span>
                            </label>
                            {condition === "Other" && data.medical_conditions.includes("Other") && (
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className="mt-2 relative rounded-md shadow-sm w-full"
                                >
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <FaEdit className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        type="text"
                                        name="other_medical_condition"
                                        value={data.other_medical_condition}
                                        onChange={handleChange}
                                        placeholder="Please specify"
                                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                    />
                                </motion.div>
                            )}
                        </motion.div>
                    ))}
                </div>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mb-4"
            >
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Do you have any surgical history? Please list any surgeries below or put N/A if not applicable.
                </label>
                <textarea
                    name="surgical_history"
                    value={data.surgical_history}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                    rows="4"
                />
            </motion.div>
        </motion.div>
    );
};

export default MedicalHistory;
