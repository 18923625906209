import React from 'react';
import { motion } from 'framer-motion';
import { FaCheckSquare, FaSquare } from 'react-icons/fa';

const RedFlagSymptoms = ({ data, setData }) => {
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setData({ ...data, red_flags: [...data.red_flags, name] });
        } else {
            setData({ 
                ...data, 
                red_flags: data.red_flags.filter(flag => flag !== name) 
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const redFlagSymptoms = [
        "Rapid, unexplained weight loss (>10 lbs).",
        "Persistent chest pain, unrelieved by rest.",
        "Vomiting blood.",
        "Black, tarry stools.",
        "Sudden severe headache with neck stiffness or visual changes.",
        "Difficulty breathing, sudden and severe.",
        "High fever (>101°F) persisting despite medication.",
        "Severe abdominal pain with vomiting and inability to pass stool or gas.",
        "Sudden numbness, weakness, or speech difficulties.",
        "Sudden or severe abdominal swelling or bloating, with pain or breathing difficulties.",
        "N/A"
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
        >
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Red Flag Symptoms
            </h2>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mb-6"
            >
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Do you have any "RED FLAG" symptoms requiring immediate medical attention? Review the list below and check any applicable boxes. If you select any serious red flags, you will not be deemed "fit" for Physical Therapy and should seek medical attention promptly.
                </label>
                <div className="mt-2 space-y-2 max-h-60 overflow-y-auto pr-2">
                    {redFlagSymptoms.map(flag => (
                        <motion.div
                            key={flag}
                            whileHover={{ scale: 1.02 }}
                            className="flex items-center"
                        >
                            <label className="inline-flex items-center cursor-pointer w-full">
                                <input
                                    type="checkbox"
                                    name={flag}
                                    checked={data.red_flags.includes(flag)}
                                    onChange={handleCheckboxChange}
                                    className="hidden"
                                />
                                {data.red_flags.includes(flag) ? (
                                    <FaCheckSquare className="text-teal-500 mr-2" />
                                ) : (
                                    <FaSquare className="text-gray-300 mr-2" />
                                )}
                                <span className="text-sm text-gray-700">{flag}</span>
                            </label>
                        </motion.div>
                    ))}
                </div>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mb-6"
            >
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Would you like a referral for Physical Therapy from one of our contracted physicians? They will review your entire questionnaire to determine if you are suitable for a PT appointment.
                </label>
                <div className="mt-2 space-x-4">
                    {['Yes', 'No'].map((value) => (
                        <label key={value} className="inline-flex items-center">
                            <input
                                type="radio"
                                name="pt_referral"
                                value={value}
                                checked={data.pt_referral === value}
                                onChange={handleChange}
                                className="form-radio text-teal-600 focus:ring-teal-500"
                            />
                            <span className="ml-2 text-sm text-gray-700">{value}</span>
                        </label>
                    ))}
                </div>
            </motion.div>
        </motion.div>
    );
};

export default RedFlagSymptoms;
