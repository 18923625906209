import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import { FaExclamationTriangle, FaLock, FaUserShield, FaEye } from 'react-icons/fa';

export function DoctorWarningPage() {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/doctor-dashboard');
  };

  const WarningItem = ({ icon: Icon, text }) => (
    <motion.div
      className="flex items-center mb-4"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Icon className="text-teal-500 mr-3 text-xl" />
      <Typography variant="body1">{text}</Typography>
    </motion.div>
  );

  return (
    <Container maxWidth="md" className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white shadow-2xl rounded-lg overflow-hidden w-full max-w-md"
      >
        <div className="bg-gradient-to-r from-teal-400 to-teal-600 px-6 py-8 text-white">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 260, damping: 20 }}
          >
            <FaExclamationTriangle className="text-6xl mx-auto mb-4" />
          </motion.div>
          <Typography variant="h4" className="text-center font-bold">
            Protected Health Information
          </Typography>
        </div>
        <Box className="px-6 py-8">
          <Typography variant="body1" className="text-gray-600 mb-6 text-center">
            You are about to view protected health information. Please ensure that:
          </Typography>
          <WarningItem icon={FaLock} text="You are in a private location" />
          <WarningItem icon={FaUserShield} text="You are using a secure computer" />
          <WarningItem icon={FaEye} text="No unauthorized individuals can view your screen" />
          <Typography variant="body2" className="text-gray-500 mt-6 mb-8 text-center">
            By clicking "Continue", you confirm that you are in compliance with HIPAA regulations and your organization's policies regarding the handling of protected health information.
          </Typography>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={handleContinue}
              className="bg-gradient-to-r from-teal-400 to-teal-600 text-white font-bold py-3 rounded-full shadow-lg hover:shadow-xl transition duration-300"
            >
              Continue to Doctor Dashboard
            </Button>
          </motion.div>
        </Box>
      </motion.div>
    </Container>
  );
}