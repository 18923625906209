import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
    const progress = (currentStep / totalSteps) * 100;

    return (
        <div className="w-full">
            <div className="flex justify-between mb-2">
                {Array.from({ length: totalSteps }, (_, i) => (
                    <div
                        key={i}
                        className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-semibold ${
                            i < currentStep
                                ? 'bg-teal-600 text-white'
                                : i === currentStep - 1
                                ? 'bg-teal-200 text-teal-800'
                                : 'bg-gray-200 text-gray-600'
                        }`}
                    >
                        {i + 1}
                    </div>
                ))}
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-teal-200">
                <div
                    style={{ width: `${progress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500 transition-all duration-300 ease-in-out"
                ></div>
            </div>
        </div>
    );
};

export default ProgressBar;