import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaUserMd, FaClipboardCheck, FaChartLine, FaHandHoldingMedical } from 'react-icons/fa';
import logo from "../assets/logo.png";
import bgImage from "../assets/exampleTherapyImage.jpg";

const DoctorHomePage = () => {
  const FeatureCard = ({ icon: Icon, title, description }) => (
    <motion.div 
      className="bg-white p-6 rounded-lg shadow-xl"
      whileHover={{ scale: 1.05, boxShadow: "0 10px 40px rgba(0, 0, 0, 0.1)" }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <Icon className="text-4xl text-teal-500 mb-4" />
      <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gray-100 overflow-hidden">
      <div className="relative">
        <div className="absolute inset-0 z-0">
          <img src={bgImage} alt="Background" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <img src={logo} alt="TherRx Logo" className="h-24 w-auto mx-auto mb-8" />
            <div className="bg-black bg-opacity-50 p-6 rounded-lg inline-block">
              <h1 className="text-4xl sm:text-5xl font-extrabold text-white mb-6">
                Welcome, Doctor
              </h1>
              <p className="text-xl text-white mb-8 max-w-3xl mx-auto">
                Your expertise is crucial in providing remote physical therapy prescriptions. Let's make healthcare more accessible together.
              </p>
              <Link
                to="/doctor-warning"
                className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 md:text-lg md:px-10 transition duration-300 transform hover:scale-105"
              >
                Go to Dashboard
              </Link>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Your Role at TherRx
            </h2>
            <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
              As a valued medical professional, you play a crucial part in our innovative approach to physical therapy prescriptions.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <FeatureCard 
              icon={FaUserMd}
              title="Expert Review"
              description="Assess patient information and provide professional medical opinions for physical therapy needs."
            />
            <FeatureCard 
              icon={FaClipboardCheck}
              title="Prescription Approval"
              description="Review and approve physical therapy prescriptions based on comprehensive patient data."
            />
            <FeatureCard 
              icon={FaChartLine}
              title="Patient Progress Monitoring"
              description="Track and evaluate patient progress through our advanced dashboard system."
            />
          </div>
        </div>
      </div>

      <motion.div 
        className="bg-teal-700 text-white py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-extrabold sm:text-4xl mb-8">
            Ready to Make a Difference?
          </h2>
          <Link
            to="/doctor-warning"
            className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-teal-700 bg-white hover:bg-gray-100 md:text-lg md:px-10 transition duration-300 transform hover:scale-105"
          >
            Access Your Dashboard
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default DoctorHomePage;