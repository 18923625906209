import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../components/RealmApp';
import { motion } from 'framer-motion';
import { FaUserInjured, FaFileMedical, FaSearch } from 'react-icons/fa';

const DoctorDashboard = () => {
    const [availablePatients, setAvailablePatients] = useState([]);
    const [pdfPatients, setPdfPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const app = useApp();
    const doctor = app.currentUser;
    const apiUrl = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;

    useEffect(() => {
        const fetchAvailablePatients = async () => {
            try {
                const response = await axios.get(`${apiUrl}/patients/available`);
                setAvailablePatients(response.data);
            } catch (error) {
                console.error("Failed to fetch available patients:", error);
            }
        };

        const fetchPdfPatients = async () => {
            try {
                const response = await axios.get(`${apiUrl}/pdfs/doctor/${doctor.id}`);
                setPdfPatients(response.data);
            } catch (error) {
                console.error("Failed to fetch patients with PDFs:", error);
            }
        };

        fetchAvailablePatients();
        fetchPdfPatients();
    }, [doctor.id, apiUrl]);

    const filteredAvailablePatients = availablePatients.filter(patient =>
        patient.complete_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredPdfPatients = pdfPatients.filter(patient =>
        patient.complete_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1 className="text-3xl font-bold text-gray-900 mb-6">Doctor Dashboard</h1>
                    <div className="mb-6">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search patients..."
                                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <FaSearch className="absolute left-3 top-3 text-gray-400" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <motion.div
                            className="bg-white overflow-hidden shadow-xl sm:rounded-lg"
                            whileHover={{ scale: 1.02 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <div className="px-4 py-5 sm:p-6">
                                <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                                    <FaUserInjured className="mr-2 text-teal-500" /> Available Patients
                                </h2>
                                {filteredAvailablePatients.length > 0 ? (
                                    <ul className="divide-y divide-gray-200">
                                        {filteredAvailablePatients.map(patient => (
                                            <li key={patient.userId} className="py-4">
                                                <button
                                                    onClick={() => navigate(`/patient/${patient.userId}`, { state: { patient } })}
                                                    className="text-teal-600 hover:text-teal-800 font-medium"
                                                >
                                                    {patient.complete_name}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500">No available patients found.</p>
                                )}
                            </div>
                        </motion.div>
                        <motion.div
                            className="bg-white overflow-hidden shadow-xl sm:rounded-lg"
                            whileHover={{ scale: 1.02 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <div className="px-4 py-5 sm:p-6">
                                <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                                    <FaFileMedical className="mr-2 text-teal-500" /> Your Patients (Patients with PDFs uploaded by you)
                                </h2>
                                {filteredPdfPatients.length > 0 ? (
                                    <ul className="divide-y divide-gray-200">
                                        {filteredPdfPatients.map(patient => (
                                            <li key={patient.userId} className="py-4">
                                                <button
                                                    onClick={() => navigate(`/patient/${patient.userId}`, { state: { patient } })}
                                                    className="text-teal-600 hover:text-teal-800 font-medium"
                                                >
                                                    {patient.complete_name}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-500">No patients found.</p>
                                )}
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default DoctorDashboard;
