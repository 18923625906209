import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Realm from "realm-web";
import { useApp } from "./RealmApp";
import { motion } from "framer-motion";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { MoreInfoDocsLink } from "./MoreInfo";
import { toggleBoolean } from "../utils";
import { useErrorAlert } from "../hooks/useErrorAlert";
import logo from "../assets/logo.png";

export function WelcomePage() {
  const app = useApp();
  const navigate = useNavigate();

  const [isSignup, setIsSignup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email: null,
    password: null,
    other: null,
  });

  const toggleIsSignup = () => {
    clearErrors();
    setIsSignup(prev => !prev);
  };

  const clearErrors = () => setError({ email: null, password: null, other: null });

  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });

  const toggleShowPassword = () => setShowPassword(toggleBoolean);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    clearErrors();
    try {
      if (isSignup) {
        await app.emailPasswordAuth.registerUser({ email, password });
      }
      await app.logIn(Realm.Credentials.emailPassword(email, password));
      navigate('/home');
    } catch (err) {
      console.error("Authentication error:", err);
      if (err.statusCode === 401) {
        setError((prevError) => ({
          ...prevError,
          password: "Incorrect email or password. Please try again.",
        }));
      } else {
        handleAuthenticationError(err, setError);
      }
    }
  };

  const handleForgotPassword = () => {
    navigate('/reset-password');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="sm:mx-auto sm:w-full sm:max-w-md"
      >
        <img src={logo} alt="TherRx Logo" className="mx-auto h-20 w-auto" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {isSignup ? "Create your account" : "Sign in to your account"}
        </h2>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
      >
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={onFormSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaEnvelope className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`block w-full pl-10 sm:text-sm border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500 ${
                    error.email ? "border-red-300" : ""
                  }`}
                  placeholder="you@example.com"
                />
              </div>
              {error.email && (
                <p className="mt-2 text-sm text-red-600">{error.email}</p>
              )}
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaLock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`block w-full pl-10 pr-10 sm:text-sm border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500 ${
                    error.password ? "border-red-300" : ""
                  }`}
                  placeholder="••••••••"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                  >
                    {showPassword ? <FaEyeSlash className="h-5 w-5" /> : <FaEye className="h-5 w-5" />}
                  </button>
                </div>
              </div>
              {error.password && (
                <p className="mt-2 text-sm text-red-600">{error.password}</p>
              )}
            </div>

            {NonAuthErrorAlert}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                {isSignup ? "Sign Up" : "Sign In"}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <button
              onClick={toggleIsSignup}
              className="w-full text-center text-sm text-teal-600 hover:text-teal-500"
            >
              {isSignup ? "Already have an account? Sign In" : "Don't have an account? Sign Up"}
            </button>
          </div>

          <div className="mt-6">
            <button
              onClick={handleForgotPassword}
              className="w-full text-center text-sm text-gray-600 hover:text-gray-500"
            >
              Forgot your password?
            </button>
          </div>

          <div className="mt-6">
            <MoreInfoDocsLink />
          </div>
        </div>
      </motion.div>
    </div>
  );
}

function handleAuthenticationError(err, setError) {
  const handleUnknownError = () => {
    setError((prevError) => ({
      ...prevError,
      other: "Something went wrong. Try again in a little bit.",
    }));
    console.warn(
      "Something went wrong with a login or signup request. See the following error for details."
    );
    console.error(err);
  };
  if (err instanceof Realm.MongoDBRealmError) {
    const { error, statusCode } = err;
    const errorType = error || statusCode;
    switch (errorType) {
      case "invalid username":
      case "email invalid":
        setError((prevError) => ({
          ...prevError,
          email: "Invalid email address.",
        }));
        break;
      case "invalid username/password":
      case "invalid password":
      case 401:
        setError((prevError) => ({
          ...prevError,
          password: "Incorrect password.",
        }));
        break;
      case "name already in use":
      case 409:
        setError((prevError) => ({
          ...prevError,
          email: "Email is already registered. Please log in instead.",
        }));
        break;
      case "password must be between 6 and 128 characters":
      case 400:
        setError((prevError) => ({
          ...prevError,
          password: "Password must be between 6 and 128 characters.",
        }));
        break;
      default:
        handleUnknownError();
        break;
    }
  } else {
    handleUnknownError();
  }
}

export default WelcomePage;

