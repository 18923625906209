import React from 'react';
import { FaCheckSquare, FaSquare } from 'react-icons/fa';

const PainAssessment = ({ data, setData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setData({ ...data, medications: [...data.medications, name] });
        } else {
            setData({
                ...data,
                medications: data.medications.filter(med => med !== name)
            });
        }
    };

    const painAreas = [
        "Head (including headaches)",
        "Neck (Cervical region)",
        "Shoulders",
        "Upper back (Thoracic region)",
        "Elbows",
        "Hands and wrists",
        "Chest (Thoracic region)",
        "Lower back (Lumbar region)",
        "Hips",
        "Abdomen",
        "Pelvis",
        "Buttocks",
        "Thighs (Upper legs)",
        "Knees",
        "Ankles and feet",
    ];

    const medicationOptions = [
        "Over-the-counter pain relievers",
        "Opioid medications",
        "Topical anti-inflammatory medications",
        "Other",
    ];

    const RadioGroup = ({ label, name, options }) => (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-2 space-y-2">
                {options.map((option) => (
                    <label key={option.value} className="inline-flex items-center mr-4">
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={data[name] === option.value}
                            onChange={handleRadioChange}
                            className="form-radio text-teal-600 focus:ring-teal-500"
                        />
                        <span className="ml-2 text-sm text-gray-700">{option.label}</span>
                    </label>
                ))}
            </div>
        </div>
    );

    return (
        <div className="space-y-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Pain Assessment
            </h2>

            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Where is your pain located?
                </label>
                <select
                    name="pain_area"
                    value={data.pain_area}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm rounded-md"
                >
                    <option value="">Select pain area</option>
                    {painAreas.map((area) => (
                        <option key={area} value={area}>
                            {area}
                        </option>
                    ))}
                </select>
            </div>

            <RadioGroup
                label="Which side is affected?"
                name="affected_side"
                options={[
                    { value: "Left", label: "Left" },
                    { value: "Right", label: "Right" },
                    { value: "Both", label: "Both" },
                ]}
            />

            <RadioGroup
                label="How long have you been experiencing pain?"
                name="pain_duration"
                options={[
                    { value: "1-3 days", label: "1-3 days" },
                    { value: "4 days - 8 weeks", label: "4 days - 8 weeks" },
                    { value: "Greater than 8 weeks", label: "Greater than 8 weeks" },
                ]}
            />

            <RadioGroup
                label="How would you describe the pain you are experiencing?"
                name="pain_description"
                options={[
                    { value: "Acute (1-3 days)", label: "Acute (1-3 days)" },
                    { value: "Subacute (4 days - 8 weeks)", label: "Subacute (4 days - 8 weeks)" },
                    { value: "Chronic (Greater than 8 weeks)", label: "Chronic (Greater than 8 weeks)" },
                ]}
            />

            <RadioGroup
                label="Do you experience numbness or tingling?"
                name="numbness_tingling"
                options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                ]}
            />

            {data.numbness_tingling === "Yes" && (
                <RadioGroup
                    label="If you responded yes, do you experience any bowel or bladder issues? If you select 'Yes,' you will not qualify for Physical Therapy due to the presence of red flag symptoms."
                    name="bowel_blader_issues"
                    options={[
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                    ]}
                />
            )}

            <RadioGroup
                label="Does the intensity of your pain vary throughout the day? For instance, is it more severe in the morning and improves as the day progresses, or is it vice versa? Please describe how your pain behaves."
                name="pain_intensity"
                options={[
                    { value: "Worse in the morning and better in the evening.", label: "Worse in the morning and better in the evening." },
                    { value: "Better in the morning and worse in the evening.", label: "Better in the morning and worse in the evening." },
                    { value: "Only hurts when performing certain movements.", label: "Only hurts when performing certain movements." },
                    { value: "Totally random and I am unsure when my pain comes on.", label: "Totally random and I am unsure when my pain comes on." },
                ]}
            />

            <RadioGroup
                label="Does your pain restrict your ability to engage in activities that you previously could do without experiencing pain?"
                name="pain_restriction"
                options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                ]}
            />

            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Are you currently taking any medications for your pain?
                </label>
                <div className="mt-2 space-y-2">
                    {medicationOptions.map((option) => (
                        <div key={option} className="flex items-center">
                            <label className="inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    name={option}
                                    checked={data.medications.includes(option)}
                                    onChange={handleCheckboxChange}
                                    className="form-checkbox text-teal-600 focus:ring-teal-500"
                                />
                                <span className="ml-2 text-sm text-gray-700">{option}</span>
                            </label>
                        </div>
                    ))}
                </div>
                {data.medications.includes("Other") && (
                    <input
                        type="text"
                        name="other_medication"
                        value={data.other_medication}
                        onChange={handleChange}
                        placeholder="Please specify"
                        className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                    />
                )}
            </div>

            <RadioGroup
                label="Have you previously received physical therapy for this injury? Please respond with 'Yes' or 'No'."
                name="previous_pt"
                options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                ]}
            />
        </div>
    );
};

export default PainAssessment;