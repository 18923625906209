import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Box, IconButton, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { useApp } from './RealmApp';
import logo from '../assets/logo.png';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHome, FaClipboardList, FaFileAlt, FaUserMd, FaSignOutAlt, FaSignInAlt, FaBars } from 'react-icons/fa';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(90deg, #2dd4bf 0%, #14b8a6 100%)',
  boxShadow: 'none',
  borderBottom: '2px solid rgba(255, 255, 255, 0.2)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    borderBottom: '2px solid rgba(255, 255, 255, 0.5)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#ffffff',
  fontWeight: 600,
  margin: '0 8px',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '0%',
    height: '2px',
    bottom: 0,
    left: '50%',
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease-in-out',
  },
  '&:hover::after': {
    width: '100%',
    left: '0%',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0f766e',
  color: '#ffffff',
  fontWeight: 600,
  padding: '8px 16px',
  borderRadius: '20px',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#115e59',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#1e3a8a', // Navy blue background
    color: '#ffffff',
  },
  '& .MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: '#ffffff',
}));

const Navbar = () => {
    const { currentUser, logOut } = useApp();
    const [userType, setUserType] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchUserType = async () => {
            if (currentUser) {
                try {
                    const response = await axios.get(`${apiUrl}/check-user/${currentUser.id}`);
                    setUserType(response.data.userType);
                } catch (error) {
                    console.error("Failed to fetch user type:", error);
                    setUserType('unknown'); // Set a default value
                } finally {
                    setLoading(false);
                }
            } else {
                setUserType(null);
                setLoading(false);
            }
        };

        fetchUserType();
    }, [currentUser]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logOut();
        handleClose();
        navigate('/welcome');
    };

    if (loading) {
        return (
            <StyledAppBar position="sticky">
                <Toolbar>
                    <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    >
                        <FaHome size={24} color="#ffffff" />
                    </motion.div>
                </Toolbar>
            </StyledAppBar>
        );
    }

    const NavItems = () => (
        <>
            <Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
                <StyledMenuItem>
                    <FaHome style={{ marginRight: '8px' }} />
                    Home
                </StyledMenuItem>
            </Link>
            {userType !== 'doctor' && (
                <Link to="/form" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledMenuItem>
                        <FaClipboardList style={{ marginRight: '8px' }} />
                        Form
                    </StyledMenuItem>
                </Link>
            )}
            {!loading && userType === 'patient' && (
                <Link to="/my-documents" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledMenuItem>
                        <FaFileAlt style={{ marginRight: '8px' }} />
                        My Documents
                    </StyledMenuItem>
                </Link>
            )}
            {userType === 'doctor' && (
                <Link to="/doctor-warning" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledMenuItem>
                        <FaUserMd style={{ marginRight: '8px' }} />
                        Doctor Dashboard
                    </StyledMenuItem>
                </Link>
            )}
        </>
    );

    return (
        <StyledAppBar position="sticky" className="bg-gradient-to-r from-teal-400 to-teal-600">
            <Toolbar>
                <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Link to="/home" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <img src={logo} alt="Logo" style={{ height: 40, marginRight: 8 }} />
                    </Link>
                </motion.div>
                <Box sx={{ flexGrow: 1 }} />
                <AnimatePresence>
                    {isMobile ? (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.5 }}
                            transition={{ duration: 0.3 }}
                        >
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleMenu}
                            >
                                <FaBars />
                            </IconButton>
                            <StyledMenu
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <NavItems />
                                {currentUser ? (
                                    <StyledMenuItem onClick={handleLogout}>
                                        <FaSignOutAlt style={{ marginRight: '8px' }} />
                                        Log Out
                                    </StyledMenuItem>
                                ) : (
                                    <StyledMenuItem onClick={() => { handleClose(); navigate('/welcome'); }}>
                                        <FaSignInAlt style={{ marginRight: '8px' }} />
                                        Log In
                                    </StyledMenuItem>
                                )}
                            </StyledMenu>
                        </motion.div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 20 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <NavItems />
                            {currentUser ? (
                                <ActionButton
                                    onClick={handleLogout}
                                    startIcon={<FaSignOutAlt />}
                                >
                                    <Typography variant="button">Log Out</Typography>
                                </ActionButton>
                            ) : (
                                <ActionButton
                                    onClick={() => navigate('/welcome')}
                                    startIcon={<FaSignInAlt />}
                                >
                                    <Typography variant="button">Log In</Typography>
                                </ActionButton>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>
            </Toolbar>
        </StyledAppBar>
    );
};

export default Navbar;