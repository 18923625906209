import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaFileAlt, FaUserMd } from 'react-icons/fa';

const Success = () => {
    return (
        <div className="bg-gray-100 w-full min-h-screen flex flex-col justify-center px-4 sm:px-6 lg:px-8">
            <motion.div 
                className="sm:mx-auto sm:w-full sm:max-w-md text-center"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <FaCheckCircle className="mx-auto text-6xl text-teal-500 mb-4" />
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                    Form Submitted Successfully!
                </h1>
                <p className="mt-4 text-gray-700">
                    Your health questionnaire has been received and will be reviewed by our medical team.
                </p>
            </motion.div>
            <motion.div 
                className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
            >
                <div className="bg-white py-8 px-4 shadow-xl sm:rounded-lg sm:px-10">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <motion.div 
                            className="bg-gray-50 p-6 rounded-lg shadow-md"
                            whileHover={{ scale: 1.03 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <FaFileAlt className="text-3xl text-teal-500 mb-4" />
                            <h3 className="text-lg font-bold text-gray-900 mb-2">Next Step: My Documents</h3>
                            <p className="text-gray-600">
                                You can now view your submitted information in the <Link to="/my-documents" className="text-teal-600 font-semibold hover:underline">My Documents</Link> section.
                            </p>
                        </motion.div>
                        <motion.div 
                            className="bg-gray-50 p-6 rounded-lg shadow-md"
                            whileHover={{ scale: 1.03 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <FaUserMd className="text-3xl text-teal-500 mb-4" />
                            <h3 className="text-lg font-bold text-gray-900 mb-2">Doctor Review</h3>
                            <p className="text-gray-600">
                                A doctor will review your form. If approved, a prescription PDF will be uploaded to your <Link to="/my-documents" className="text-teal-600 font-semibold hover:underline">My Documents</Link> section.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
            <motion.div 
                className="mt-8 text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
            >
                <Link 
                    to="/home" 
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition duration-300"
                >
                    Back to Home
                </Link>
            </motion.div>
        </div>
    );
}

export default Success;
