import React, { useEffect, useState } from 'react';
import { useApp } from '../components/RealmApp';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaFileAlt, FaPrescription, FaUserCircle } from 'react-icons/fa';

const MyDocuments = () => {
    const [submissionData, setSubmissionData] = useState(null);
    const [pdfs, setPdfs] = useState([]);
    const app = useApp();
    const user = app.currentUser;
    const userId = user?.id;
    const navigate = useNavigate();
    axios.defaults.withCredentials = true;
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!userId) throw new Error("User ID not found");

                const [submissionResponse, pdfsResponse] = await Promise.all([
                    axios.get(`${apiUrl}/submission/${userId}`, {
                        headers: { Authorization: `Bearer ${user.accessToken}` }
                    }),
                    axios.get(`${apiUrl}/pdfs/${userId}`, {
                        headers: { Authorization: `Bearer ${user.accessToken}` }
                    })
                ]);

                setSubmissionData(submissionResponse.data);
                setPdfs(pdfsResponse.data);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        fetchData();
    }, [userId, user.accessToken]);

    if (!submissionData) {
        return (
            <motion.div 
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ duration: 0.3 }}
                className="min-h-screen bg-gray-100 flex items-center justify-center p-4"
            >
                <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
                    <h1 className="text-3xl font-bold text-gray-900 mb-4">My Documents</h1>
                    <p className="text-gray-600 mb-6">
                        You haven't submitted any documents yet. Start your journey to better health by completing our comprehensive health questionnaire.
                    </p>
                    <Link 
                        to="/form" 
                        className="block w-full text-center bg-teal-600 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                    >
                        Complete Health Questionnaire
                    </Link>
                </div>
            </motion.div>
        );
    }

    const excludedFields = ['_id', 'userId', 'files', '__v'];

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1 className="text-3xl font-bold text-gray-900 mb-6">My Health Documents</h1>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <motion.div 
                            className="bg-white rounded-lg shadow-xl overflow-hidden"
                            whileHover={{ scale: 1.01 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <div className="px-4 py-5 sm:p-6">
                                <h2 className="text-2xl font-semibold text-gray-900 mb-4 flex items-center">
                                    <FaUserCircle className="mr-2 text-teal-500" /> My Health Profile
                                </h2>
                                <div className="space-y-3">
                                    {Object.entries(submissionData)
                                        .filter(([key]) => !excludedFields.includes(key))
                                        .map(([key, value]) => (
                                            <div key={key} className="flex items-start bg-gray-50 p-3 rounded-lg">
                                                <span className="font-medium text-gray-700 min-w-[150px] capitalize">{key.replace(/_/g, ' ')}:</span>
                                                <span className="text-gray-600">{Array.isArray(value) ? value.join(', ') : value}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </motion.div>

                        <motion.div 
                            className="bg-white rounded-lg shadow-xl overflow-hidden"
                            whileHover={{ scale: 1.01 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <div className="px-4 py-5 sm:p-6">
                                <h2 className="text-2xl font-semibold text-gray-900 mb-4 flex items-center">
                                    <FaPrescription className="mr-2 text-teal-500" /> My Prescriptions
                                </h2>
                                {pdfs.length > 0 ? (
                                    <ul className="space-y-3">
                                        {pdfs.map((pdf) => (
                                            <motion.li 
                                                key={pdf._id}
                                                whileHover={{ scale: 1.02 }}
                                                className="bg-gray-50 p-3 rounded-lg flex items-center"
                                            >
                                                <FaFileAlt className="text-teal-500 mr-3" />
                                                <a
                                                    href={`${apiUrl}/pdf/${pdf._id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-teal-600 hover:text-teal-800 font-medium"
                                                >
                                                    {pdf.pdfName}
                                                </a>
                                            </motion.li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-600">No prescriptions available yet. A doctor will review your submission and provide prescriptions if necessary.</p>
                                )}
                            </div>
                        </motion.div>
                    </div>

                    <motion.div 
                        className="mt-8 text-center"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                    >
                        <Link 
                            to="/home" 
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                            Back to Home
                        </Link>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};

export default MyDocuments;
