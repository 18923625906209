import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StyledEngineProvider,
  } from "@mui/material/styles";
  import { colors } from "../colors";
  const themeConfig = {
  palette: {
  primary: colors.blue, // Assuming you want to use blue as shown in your form
  secondary: colors.green,
  },
  }
  const theme = createTheme(themeConfig);
  export function ThemeProvider({ children }) {
  return (
  <StyledEngineProvider injectFirst>
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </StyledEngineProvider>
  );
  }