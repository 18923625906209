import React from 'react';
import { useApp } from "../components/RealmApp";
import { FaEnvelope, FaUser, FaCalendar, FaHospital, FaIdCard, FaHome, FaBuilding } from 'react-icons/fa';

const BasicInformation = React.memo(({ data, setData }) => {
    const app = useApp();
    const userId = app.currentUser.id;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    Basic Information
                </h1>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="email">
                                What is your email address?
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaEnvelope className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={data.email}
                                    onChange={handleChange}
                                    required
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="complete_name">
                                What is your complete name? (Last name, First name)
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaUser className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="complete_name"
                                    id="complete_name"
                                    value={data.complete_name}
                                    onChange={handleChange}
                                    required
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="dob">
                                What is your date of birth? (MM/DD/YYYY)
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaCalendar className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="date"
                                    name="dob"
                                    id="dob"
                                    value={data.dob}
                                    onChange={handleChange}
                                    required
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="insurance_provider">
                                Who is your primary insurance provider?
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaHospital className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="insurance_provider"
                                    id="insurance_provider"
                                    value={data.insurance_provider}
                                    onChange={handleChange}
                                    required
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="insurance_policy_number">
                                What is your insurance policy number?
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaIdCard className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="insurance_policy_number"
                                    id="insurance_policy_number"
                                    value={data.insurance_policy_number}
                                    onChange={handleChange}
                                    required
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="insurance_group_number">
                                What is your insurance group number?
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaIdCard className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="insurance_group_number"
                                    id="insurance_group_number"
                                    value={data.insurance_group_number}
                                    onChange={handleChange}
                                    required
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="address">
                                Please provide your current address, including street address, state, and zip code, which is associated with your medical insurance. (Street, State, ZIP Code)
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaHome className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="address"
                                    id="address"
                                    value={data.address}
                                    onChange={handleChange}
                                    required
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="apartment">
                                Apartment Number or Unit (if applicable)
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaBuilding className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="apartment"
                                    id="apartment"
                                    value={data.apartment}
                                    onChange={handleChange}
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Do you have a secondary insurer?
                            </label>
                            <div className="mt-2 space-x-4">
                                {['yes', 'no'].map((value) => (
                                    <label key={value} className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            name="has_secondary_insurance"
                                            value={value}
                                            checked={data.has_secondary_insurance === value}
                                            onChange={handleChange}
                                            className="form-radio h-4 w-4 text-teal-600 transition duration-150 ease-in-out"
                                        />
                                        <span className="ml-2 capitalize">{value}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                    {data.has_secondary_insurance === "yes" && (
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div className="col-span-3">
                                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="secondary_insurance_details">
                                    If so, please enter your Secondary Insurer, Group Number, and Policy Number like this: (Secondary Insurer, Group Number, Policy Number)
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <FaHospital className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input
                                        type="text"
                                        name="secondary_insurance_details"
                                        id="secondary_insurance_details"
                                        value={data.secondary_insurance_details}
                                        onChange={handleChange}
                                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="pays_for_visits">
                                Do you typically pay for office visits to your physician, excluding co-payments?
                            </label>
                            <select
                                id="pays_for_visits"
                                name="pays_for_visits"
                                value={data.pays_for_visits}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm rounded-md"
                            >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="prescribed_medications">
                                Please add ALL prescribed medications you are currently taking. If you do not have any, please write N/A.
                            </label>
                            <textarea
                                id="prescribed_medications"
                                name="prescribed_medications"
                                value={data.prescribed_medications}
                                onChange={handleChange}
                                required
                                rows="3"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                            />
                        </div>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="col-span-3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="medications_or_supplements">
                                Please list any medications or supplements you are currently taking including over the counter (OTC) medications, NSAIDs, anti-histamines, anabolic steroids, testerone boosters, herbal supplements, and any other relevant substances. If not applicable, please write N/A.
                            </label>
                            <textarea
                                id="medications_or_supplements"
                                name="medications_or_supplements"
                                value={data.medications_or_supplements}
                                onChange={handleChange}
                                required
                                rows="4"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                            />
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    );
});

export default BasicInformation;
