import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicInformation from "../components/BasicInformation";
import MedicalHistory from "../components/MedicalHistory";
import PainAssessment from "../components/PainAssessment";
import RedFlagSymptoms from "../components/RedFlagSymptoms";
import logo from "../assets/logo.png";
import axios from "axios";
import { useApp } from "../components/RealmApp";
import ProgressBar from '../components/ProgressBar';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Form = () => {
    const [page, setPage] = useState(0);
    const [data, setData] = useState({
        email: "",
        complete_name: "",
        dob: "",
        insurance_provider: "",
        insurance_policy_number: "",
        insurance_group_number: "",
        address: "",
        apartment: "",
        has_secondary_insurance: "no",
        secondary_insurance_details: "",
        pays_for_visits: "no",
        prescribed_medications: "",
        medications_or_supplements: "",
        medical_conditions: [],
        other_medical_condition: "",
        surgical_history: "",
        pain_area: "",
        affected_side: "",
        pain_duration: "",
        pain_description: "",
        numbness_tingling: "",
        bowel_blader_issues: "",
        pain_intensity: "",
        pain_restriction: "",
        medications: [],
        other_medication: "",
        previous_pt: "",
        red_flags: [],
        pt_referral: ""
    });
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();
    const app = useApp();
    const user = app.currentUser;
    const userId = user?.id;
    axios.defaults.withCredentials = true;
    const apiUrl = process.env.REACT_APP_API_URL;

    const setFormData = useCallback((updates) => {
        setData(prevData => ({ ...prevData, ...updates }));
    }, []);

    useEffect(() => {
        const checkSubmission = async () => {
            try {
                if (!userId) {
                    throw new Error("User ID not found");
                }

                const response = await axios.get(`${apiUrl}/check-submission/${userId}`);
                if (response.data.hasSubmitted) {
                    setHasSubmitted(true);
                    alert("You have already submitted the form. Click ok to go to the home page.");
                    navigate('/my-documents');
                } else {
                    setHasSubmitted(false);
                }
            } catch (error) {
                console.error("Failed to check submission status:", error);
            }
        };

        checkSubmission();
    }, [userId, navigate]);

    if (hasSubmitted) {
        return <div>You have already submitted the form.</div>;
    }

    const registerUser = async (e) => {
        e.preventDefault();
    
        if (!userId) {
            alert("User ID not found. Please log in again.");
            return;
        }
    
        const { email, complete_name, dob, insurance_provider, insurance_policy_number, insurance_group_number, address, apartment, has_secondary_insurance, secondary_insurance_details, pays_for_visits, prescribed_medications, medications_or_supplements, medical_conditions, other_medical_condition, surgical_history, pain_area, affected_side, pain_duration, pain_description, numbness_tingling, bowel_blader_issues, pain_intensity, pain_restriction, medications, other_medication, previous_pt, red_flags, pt_referral } = data;
    
        // Ensure bowel_blader_issues has a valid value
        const validatedBowelBladerIssues = numbness_tingling === 'Yes' ? bowel_blader_issues : 'No';
    
        const payload = {
            email,
            complete_name,
            dob,
            insurance_provider,
            insurance_policy_number,
            insurance_group_number,
            address,
            apartment,
            has_secondary_insurance,
            secondary_insurance_details,
            pays_for_visits,
            prescribed_medications,
            medications_or_supplements,
            medical_conditions,
            other_medical_condition,
            surgical_history,
            pain_area,
            affected_side,
            pain_duration,
            pain_description,
            numbness_tingling,
            bowel_blader_issues: validatedBowelBladerIssues, // Use validated value
            pain_intensity,
            pain_restriction,
            medications,
            other_medication,
            previous_pt,
            red_flags,
            pt_referral,
            userId
        };
    
        try {
            const response = await axios.post(`${apiUrl}/submitForm`, payload);
            alert("Form submitted successfully");
            navigate('/success');
        } catch (err) {
            if (err.response && err.response.status === 409) {
                alert(err.response.data.message);
            } else if (err.response && err.response.data) {
                console.error("Error submitting form:", err.response.data);
                alert(`Error: ${err.response.data.message || "Submission failed"}`);
            } else {
                console.error("Error submitting form:", err);
                alert("Error submitting form");
            }
        }
    };
    

    const titles = ["Basic Information", "Medical History", "Pain Assessment", "Red Flag Symptoms"];

    const PageDisplay = () => {
        switch (page) {
            case 0:
                return <BasicInformation data={data} setData={setFormData} />;
            case 1:
                return <MedicalHistory data={data} setData={setFormData} />;
            case 2:
                return <PainAssessment data={data} setData={setFormData} />;
            case 3:
                return <RedFlagSymptoms data={data} setData={setFormData} />;
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-teal-100 to-teal-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-24 w-auto" src={logo} alt="logo" />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {titles[page]}
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="mb-6">
                        <ProgressBar currentStep={page + 1} totalSteps={titles.length} />
                    </div>

                    <motion.div
                        key={page}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.3 }}
                    >
                        {PageDisplay()}
                    </motion.div>

                    <div className="mt-6 flex justify-between">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            disabled={page === 0}
                            onClick={() => setPage(currentPage => currentPage - 1)}
                            className={`flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 ${
                                page === 0 ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            <FaArrowLeft className="mr-2" /> Previous
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={(e) => {
                                if (page === titles.length - 1) {
                                    registerUser(e);
                                } else {
                                    setPage(currentPage => currentPage + 1);
                                }
                            }}
                            className="flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                            {page === titles.length - 1 ? (
                                <>Submit <FaArrowRight className="ml-2" /></>
                            ) : (
                                <>Next <FaArrowRight className="ml-2" /></>
                            )}
                        </motion.button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;
