import { useState, useEffect } from 'react';
import axios from 'axios';
import { useApp } from '../components/RealmApp';

export const useUserType = () => {
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(true);
  const app = useApp();
  const user = app.currentUser;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserType = async () => {
      if (user) {
        try {
          const response = await axios.get(`${apiUrl}/user-type/${user.id}`, {
            headers: {
              Authorization: `Bearer ${user.accessToken}`
            }
          });
          setUserType(response.data.userType);
        } catch (error) {
          console.error('Failed to fetch user type:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUserType();
  }, [user]);

  return { userType, loading };
};

