// /App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { AppProvider, useApp } from "./components/RealmApp";
import { ThemeProvider } from "./components/Theme";
import Navbar from './components/Navbar';
import Form from './pages/Form';
import Success from './pages/Success';
import { WelcomePage } from './components/WelcomePage';  // Import the WelcomePage component
import HomePage from './components/HomePage';  // Import the HomePage component
import MyDocuments from './pages/MyDocuments'; // Import the MyDocuments component
import DoctorDashboard from './components/DoctorDashboard'; // Import the DoctorDashboard component
import PatientDetails from './pages/PatientDetails'; // Import the PatientDetails component
import axios from 'axios';
import atlasConfig from "./atlasConfig.json";
import logo from './assets/logo.png';
import ProtectedDoctorRoute from './components/ProtectedDoctorRoute';
import Unauthorized from './components/Unauthorized';
import { ResetPassword } from './components/ResetPassword'; // Import the ResetPassword component
import { CompletePasswordReset } from './components/CompletePasswordReset'; // Import the CompletePasswordReset component
import { DoctorWarningPage } from './components/DoctorWarningPage'; // Import the DoctorWarningPage component

const { appId } = atlasConfig;


export default function ProvidedApp() {
    return (
      <ThemeProvider>
        <AppProvider appId={appId}>
          <Router>
            <App />
          </Router>
        </AppProvider>
      </ThemeProvider>
    );
  }
  
function App() {
    const { currentUser } = useApp();
    const navigate = useNavigate();
    const location = useLocation();

    return (
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/form" element={currentUser ? <Form /> : <Navigate replace to="/welcome" />} />
          <Route path="/success" element={currentUser ? <Success /> : <Navigate replace to="/welcome" />} />
          <Route path="/my-documents" element={currentUser ? <MyDocuments /> : <Navigate replace to="/welcome" />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          
          <Route element={<ProtectedDoctorRoute />}>
            <Route path="/doctor-warning" element={<DoctorWarningPage />} />
            <Route path="/doctor-dashboard" element={<DoctorDashboard />} />
            <Route path="/patient/:userId" element={<PatientDetails />} />
          </Route>
          
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/complete-reset-password" element={<CompletePasswordReset />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </div>
    );
  }
