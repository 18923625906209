import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserType } from '../hooks/useUserType';
import { CircularProgress } from '@mui/material';

const ProtectedDoctorRoute = () => {
  const { userType, loading } = useUserType();

  if (loading) {
    return <CircularProgress />;
  }

  if (userType !== 'doctor') {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
};

export default ProtectedDoctorRoute;