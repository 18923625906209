import React, { useState, useEffect } from "react";
import { useApp } from "../components/RealmApp";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaClipboardList, FaUserMd, FaPrescriptionBottle, FaCheckCircle, FaDollarSign, FaHandsHelping } from 'react-icons/fa';
import logo from "../assets/logo.png";
import bgImage from "../assets/exampleTherapyImage.jpg";
import DoctorHomePage from "./DoctorHomePage";
import axios from 'axios';

const HomePage = () => {
  const app = useApp();
  const user = app.currentUser;
  const userId = user?.id;
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkAndAddUser = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${apiUrl}/check-user/${userId}`, {
            withCredentials: true,
          });
          
          if (!response.data.exists) {
            // User doesn't exist, so add them
            await axios.post(
              `${apiUrl}/addForm`,
              {
                username: user.profile.name || user.profile.email,
                email: user.profile.email,
                userId: userId,
                userType: "patient", // Default to patient
              },
              {
                withCredentials: true,
              }
            );
            // Force a page refresh after adding the user
            window.location.reload();
            return; // Exit the function early to avoid setting state on an unmounted component
          }
          
          setUserType(response.data.userType);
        } catch (error) {
          console.error("Failed to check or add user:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setUserType(null);
        setLoading(false);
      }
    };

    checkAndAddUser();
  }, [userId, apiUrl, user]);

  if (loading) {
    return <div className="flex items-center justify-center h-screen"><div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12"></div></div>;
  }

  // If the user is a doctor, render the DoctorHomePage
  if (userType === 'doctor') {
    return <DoctorHomePage />;
  }

  const FeatureCard = ({ icon: Icon, title, description }) => (
    <motion.div 
      className="bg-white p-6 rounded-lg shadow-xl"
      whileHover={{ scale: 1.05, boxShadow: "0 10px 40px rgba(0, 0, 0, 0.1)" }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <Icon className="text-4xl text-teal-500 mb-4" />
      <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gray-100 overflow-hidden">
      <div className="relative">
        <div className="absolute inset-0 z-0">
          <img src={bgImage} alt="Background" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <img src={logo} alt="TherRx Logo" className="h-24 w-auto mx-auto mb-8" />
            <div className="bg-black bg-opacity-50 p-6 rounded-lg inline-block">
              <h1 className="text-4xl sm:text-5xl font-extrabold text-white mb-6">
                Welcome to TherRx
              </h1>
              <p className="text-xl text-white mb-8 max-w-3xl mx-auto">
                Your gateway to convenient and affordable physical therapy prescriptions, without the need for in-person doctor visits.
              </p>
              {user ? (
                <Link
                  to="/form"
                  className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 md:text-lg md:px-10 transition duration-300 transform hover:scale-105"
                >
                  Start Your Assessment
                </Link>
              ) : (
                <Link
                  to="/welcome"
                  className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 md:text-lg md:px-10 transition duration-300 transform hover:scale-105"
                >
                  Get Started
                </Link>
              )}
            </div>
          </motion.div>
        </div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              How It Works
            </h2>
            <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
              Get your physical therapy prescription in three simple steps, all from the comfort of your home.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <FeatureCard 
              icon={FaClipboardList}
              title="1. Submit Your Information"
              description="Fill out our comprehensive health questionnaire, providing details about your physical health, injury, and symptoms."
            />
            <FeatureCard 
              icon={FaUserMd}
              title="2. Doctor Review"
              description="A licensed doctor will review your submission remotely, eliminating the need for an in-person visit."
            />
            <FeatureCard 
              icon={FaPrescriptionBottle}
              title="3. Receive Your Prescription"
              description="If your condition qualifies, you'll receive a prescription for physical therapy that you can use right away."
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-24 sm:py-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Benefits of TherRx
            </h2>
            <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
              Experience the advantages of our innovative approach to physical therapy prescriptions.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <FeatureCard 
              icon={FaCheckCircle}
              title="Convenience"
              description="Skip the hassle of in-person doctor's appointments. Get your prescription from the comfort of your home."
            />
            <FeatureCard 
              icon={FaDollarSign}
              title="Cost Savings"
              description="Save money by avoiding unnecessary visits to a primary care doctor. Our streamlined process reduces costs for you."
            />
            <FeatureCard 
              icon={FaHandsHelping}
              title="Expert Care"
              description="Receive professional assessment and prescriptions from qualified doctors specializing in physical therapy."
            />
          </div>
        </div>
      </div>

      <motion.div 
        className="bg-teal-700 text-white py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-extrabold sm:text-4xl mb-8">
            Ready to Start Your Journey to Better Health?
          </h2>
          <Link
            to={user ? "/form" : "/welcome"}
            className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-teal-700 bg-white hover:bg-gray-100 md:text-lg md:px-10 transition duration-300 transform hover:scale-105"
          >
            {user ? "Begin Your Assessment" : "Get Started Now"}
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default HomePage;
